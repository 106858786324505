<template>
    <div class="typewriter">
        {{ currentWord }}
        <span v-if="showCursor" class="typewriter__cursor">|</span>
    </div>
</template>

<script>
export default {
    name: "TypeWriter",
    props: {
        words: {
            type: Array,
            required: true
        },
        runtime: {
            type: Number,
            required: false,
            default: 110
        },
        breaktime: {
            type: Number,
            required: false,
            default: 2500
        },
        showCursor: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data () {
        return {
            currentWord: "",
            word: "",
            currentLetterIndex: 0,
            currentWordIndex: 0,
            direction: "forward",
            timeout: 0
        }
    },
    mounted () {
        // set the active word of the words array
        this.setActiveWord();
        // set the timeout for the loop to the prop time
        this.timeout = this.runtime;
        // initialize loop and loop the function 
        this.runLoop();
    },
    methods: {
        addOrSubtractLetter () {
            this.timeout = this.runtime;
            // add or subtract letters to word
            if (this.currentWord < this.word && this.direction === "forward") {
                this.currentWord += this.word[this.currentLetterIndex];
                this.currentLetterIndex++;
            } else if (this.direction === "backwards") {
                this.currentWord = this.currentWord.slice(0, this.currentLetterIndex - 1);
                this.currentLetterIndex--;
            }
            this.setDirection();
        },
        runLoop () {
            setTimeout(() => {
                this.addOrSubtractLetter();
                this.runLoop();
            }, this.timeout);
        },
        setDirection () {
            // check direction
            if (this.word === this.currentWord) {
                this.direction = "backwards"
                this.timeout = this.breaktime;
            }
            if (this.currentWord === "") {
                this.direction = "forward";
                this.timeout = this.breaktime;
                this.setCurrentWordIndex();
                this.setActiveWord();
            }
        },
        setActiveWord () {
            this.word = this.words[this.currentWordIndex];
        },
        setCurrentWordIndex () {
            if (this.currentWordIndex >= this.words.length) {
                this.currentWordIndex = 0;
                return;
            }
            this.currentWordIndex++;
        }
    }
}
</script>

<style scoped>
@-webkit-keyframes pulse {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
}

@keyframes pulse {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
}

.typewriter__cursor {
    -webkit-animation: pulse 1s infinite ease-out;
    -o-animation: pulse 1s infinite ease-out;
    -ms-animation: pulse 1s infinite ease-out; 
    -moz-animation: pulse 1s infinite ease-out; 
    animation: pulse 1s infinite ease-out;
}
</style>