<template>
    <div class="card" :id="id">
        <Carousel v-if="showCarousel" ref="carousel">
            <template #slides>
                <Slide v-for="(img_name, index) in project.img_names" :key="index">
                    <div class="carousel__item no-markup-text">
                        <img
                            :src="getImgPath('projects', img_name, 'webp')"
                            :alt="project.title"
                            :loading="loadingType"
                            class="card__image"
                            draggable="false"
                            width="100%"
                            height="250"
                        />
                    </div>
                </Slide>
            </template>
            <template #addons>
                <navigation />
                <pagination />
            </template>
        </Carousel>
        <img
            v-else
            :src="getImgPath('projects', project.img_names[0], 'webp')"
            :alt="project.title"
            :loading="loadingType"
            class="card__image"
            draggable="false"
            width="100%"
            height="250"
        />
        <div class="card__content">
            <ios-expand-icon class="card__expand-icon" w="20px" h="20px" @click="showImageViewer(project)" />
            <h2 class="card__content--title" v-html="project.title"></h2>
            <project-badges :badges="project.tools" />
            <p v-html="description" class="card__content--description"></p>
            <a v-if="project.project_raw_link && project.project_link" class="card__content--link" :href="project.project_raw_link" target="_blank">{{
                project.project_link
            }}</a>
            <div class="card__content--button-wrapper">
                <router-link :to="{ name: 'Projektdetails', params: { id } }" class="no-animation">
                    <button class="card__content--button" type="button">{{ $t("projects.readMore") }}</button>
                </router-link>
                <share-project-link :project="project" :project-id="id.toString()" />
            </div>
        </div>
    </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import ShareProjectLink from "./ShareProjectLink.vue";
import ProjectBadges from "./ProjectBadges.vue";
import IosExpandIcon from "vue-ionicons/dist/ios-expand.vue";

export default {
    name: "ProjectCard",
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
        ShareProjectLink,
        ProjectBadges,
        IosExpandIcon,
    },
    props: {
        project: {
            type: Object,
            required: true,
        },
        id: {
            type: Number,
            required: true,
        },
        loadingType: {
            type: String,
            required: false,
            default: "lazy",
        },
    },
    emits: ["image-viewer-visible"],
    computed: {
        description() {
            return this.project.description.substr(0, this.descrLength) + "\u2026";
        },
    },
    data() {
        return {
            descrLength: 200,
            showCarousel: this.project.img_names.length > 1,
        };
    },
    methods: {
        showImageViewer(project) {
            const imageSources = {
                img_names: project.img_names,
                img_captions: project.img_captions,
            };
            const currentSlide = this.$refs.carousel.data.currentSlide.value;
            this.$emit("image-viewer-visible", true, imageSources, currentSlide);
        },
    },
};
</script>

<style scoped>
.card {
    background-color: var(--item-background);
    border-radius: var(--border-radius);
    border: 1px solid var(--item-border);
}
.card__content {
    padding: 0 35px 35px;
}
.card__content--title {
    font-family: var(--main-font);
    letter-spacing: normal;
    margin-bottom: 12px;
}
.card__content--description {
    text-wrap: balance;
    margin: 12px 0 12px;
}
.card__image {
    width: 100%;
    border-top-right-radius: var(--border-radius);
    border-top-left-radius: var(--border-radius);
    object-fit: cover;
    height: 250px;
}
.card__expand-icon {
    position: absolute;
    margin-top: -60px;
    margin-left: -30px;
    background-image: var(--rainbow);
    color: var(--background);
    padding: 5px;
    border-radius: 15px;
}
.card__expand-icon:hover {
    filter: brightness(1.05);
    cursor: pointer;
}
.card__content--button-wrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    text-align: center;
    margin-top: 20px;
    align-items: center;
}
.card__content--button {
    cursor: pointer;
    padding: 6px 12px;
    font-size: var(--font-size-small);
    color: var(--background);
    background-image: var(--rainbow);
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    border-radius: var(--border-radius--small);
    border: none;
    font-family: var(--main-font);
    font-weight: var(--bold);
}
.card__content--button:hover {
    filter: brightness(1.05);
}
.card__content--link {
    word-break: break-all !important;
}

@media (max-width: 550px) {
    .card__content {
        padding: 0 20px 20px;
    }
}

/* Carousel css mutations */
.carousel__item {
    width: 100%;
}
.carousel__pagination {
    top: 180px;
    position: absolute;
    width: 100%;
}
@media (max-width: 900px) {
    .carousel__pagination {
        top: 80%;
    }
}
</style>
