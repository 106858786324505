import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Notifications from "@kyvg/vue3-notification";
import "./assets/css/variables.css";
import "./assets/css/global.css";
require("vue-ionicons/ionicons.css");
import { getImgPath, changeColorTheme, setLangInStorage, shareProject, setScrollAnim } from "./helper.js";
import { createI18n } from 'vue-i18n'
import { locales } from './locales.js';
import clickOutside from "./clickOutsideDirective.js";

const i18n = createI18n({
    locale: store.state.lang,
    fallbackLocale: 'en',
    messages: locales
});


createApp(App)
    .use(router)
    .use(store)
    .use(Notifications)
    .use(i18n)
    .directive('click-outside', clickOutside)
    .mixin({
        // define all global methods here
        methods: {
            getImgPath,
            changeColorTheme,
            setLangInStorage,
            shareProject,
            setScrollAnim
        }
    })
    .mount("#app");
