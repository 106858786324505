<template>
    <div class="badges">
        <span v-for="(badge, index) of sortedBadges" :key="index" class="badge">{{ badge }}</span>
    </div>
</template>

<script>
export default {
    name: "ProjectBadges",
    props: {
        badges: {
            type: Array,
            required: true
        }
    },
    computed: {
        sortedBadges () {
            return [...this.badges].sort();
        }
    },
}
</script>

<style scoped>
.badges {
    height: min-content;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
}
.badge {
    padding: 4px 7px;
    color: var(--text-light);
    background-color: var(--emo-gray);
    width: max-content;
    font-size: 14px;
    border-radius: var(--border-radius--small);
    border: 1px solid var(--item-border);
}
</style>